.footer {
  margin: 3rem auto 0;
  font-size: 1rem;
  border-top: 1.5px solid var(--outline);
  width: 100%;
}

.footer__content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem;
  width: 100%;
}

.footer__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 656px) {
    flex-direction: column;
  }
}

.col {
  width: 20%;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media only screen and (max-width: 656px) {
    text-align: center;
  }

  a {
    padding: 0.2rem 0;

    @media only screen and (max-width: 656px) {
      padding: 0.5rem 0;
    }
  }
}

.contact {
  width: 20%;
  padding: 0.5rem 0;
}

.copyright {
  text-align: center;
  font-size: 1rem;
}

.note {
  text-align: center;
  font-size: 0.7rem;
}

.logo {
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}