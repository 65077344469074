.formField {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  border: 0;

  &.row {
    flex-direction: row;

    .label {
      margin: 1rem;
      flex-grow: 1;
      text-align: left;
    }
  }
}

.input {
  background-color: var(--background);
  border-radius: 0.75em;
  border: 2px solid var(--outline);
  color: var(--primary);
  font-family: var(--font-primary);
  letter-spacing: 1px;
  font-size: 1rem;
  padding: 1rem;
}

.label {
  margin: 1rem;
  z-index: 1;
}

.checkbox {
  position: relative;

  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .label {
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 30%;
    right: 1rem;
    height: 25px;
    width: 25px;
    background-color: #eee;
    cursor: pointer;
    border-radius: 6px;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: var(--primary);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    right: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
