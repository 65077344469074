* {
  box-sizing: border-box;
}
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  text-align: center;
}

/* // TODO FIX */
.App .App {
  display: block;
  min-height: auto;
}
