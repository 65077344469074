@mixin card($padding: 4rem) {
  background-color: var(--background);
  border-radius: 0.75em;
  border: 2px solid var(--outline);
  padding: $padding;
  position: relative;

  &::before {
    background: var(--light-grey);
    border-radius: inherit;
    bottom: 0;
    box-shadow: 0 0 0 2px var(--outline);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    transform: translate3d(0, 0.75em, -1em);
    width: 100%;
  }
}
