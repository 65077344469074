// TODO: Switch theme, font-size adjustments

//
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap");

:root {
  // Colors
  --primary: #be0031;
  --secondary: #775657;
  --tertiary: #75592f;
  --error: #ba1b1b;
  --background: #fcfcfc;
  --outline: #857373;
  --text: #3d3d3d;

  // Shades
  --primary-dark: #680016;
  --secondary-dark: #44292a;
  --tertiary-dark: #422c05;
  --primary-light: #ffb3b7;
  --secondary-light: #e6bdbd;
  --tertiary-light: #e6c18e;

  // Other
  --light-grey: #ece0df;

  // Fonts
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
