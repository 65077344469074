.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.box {
  width: 100%;
  height: 100%;
  max-width: 130px;
  min-width: 130px;
  min-height: 130px;
  padding: 0.5rem;
  border-radius: 0.75em;
  border: 2px solid var(--outline);
  margin: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.flippingBox {
  position: absolute;
  top: 50%;
  left: 50%;
  background: aliceblue;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 100px;
  border-radius: 0.75em;
  border: 2px solid var(--outline);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin {
  height: 0.5rem;
  width: 0.5rem;
  display: block;
  margin: 0.5rem;
  position: relative;
  transform-origin: 150% center;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    background: var(--primary-light);
    border: 2px solid var(--primary-dark);
    transition: background 2s;
  }

  &.flip {
    height: 1rem;
    width: 1rem;
    animation: flip ease-in-out forwards 1.5s;

    &:after {
      animation: bounce cubic-bezier(0.28, 0.84, 0.42, 1) forwards 1.5s;
    }
  }

  &.flipped:after {
    background-color: aqua;
  }
}

@keyframes bounce {
  5% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }
  15% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }
  25%,
  20% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }

  55%,
  100% {
    transform-origin: top;
    transform: scalex(1) scaley(1);
  }

  40% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }

  100% {
    background-color: aqua;
  }
}

@keyframes flip {
  0%,
  15% {
    transform: rotate(0);
  }

  35%,
  100% {
    transform: rotate(180deg);
  }
}
