@import "./variables.scss";
@import "./mixins.scss";
@import "./buttons.scss";

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  background-color: var(--light-grey);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  flex-grow: 1;
  width: 100vw;
  font-size: calc(10px + 1vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main__admin {
  min-height: 100%;
}

h1,
h2,
h3 {
  margin: 1rem 0;
}

p {
  margin: 0.5rem 0;
}

a {
  color: var(--primary);
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    text-decoration: underline;
  }
}
