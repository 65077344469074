@import "../../styles/_mixins.scss"; // TODO

form {
  @include card(1rem);

  padding-bottom: 2rem;
}

.error {
  color: var(--error);
}
