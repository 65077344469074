@import "../../styles/_mixins.scss";

.wrapper {
  @include card(1rem);

  margin: 1rem;
  gap: 2rem;
  min-width: 20vw;
}

.placeholder {
  height: 66px;
}
